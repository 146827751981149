import React from "react";
import "./Chip.css";


const Chip = ({ containerClass = '', contentClass = '', chipContent = '', onClick }) => {
    return (
        <div onClick={onClick} className={"chip " + containerClass}>
            <div className={"chip-content " + contentClass}>{chipContent}</div>
        </div>
    );
};

export default Chip;