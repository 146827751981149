import Chip from "../../Chip"

type Props = { selectedFilter: string, setSelectedFilter: (val: "all" | "competitor" | "own") => void }

export default ({ selectedFilter, setSelectedFilter }: Props) => {
    return <div style={{
        height: "10%",
        display: "flex",
        alignItems: "center"
    }} className="search-results-chips" >
        <Chip
            onClick={() => setSelectedFilter('all')}
            containerClass={selectedFilter == 'all' ? 'chip-active' : ''}
            contentClass={selectedFilter == 'all' ? 'chip-content-active' : ''}
            chipContent="All" />
        <Chip
            onClick={() => setSelectedFilter('own')}
            containerClass={selectedFilter == 'own' ? 'chip-active' : ''}
            contentClass={selectedFilter == 'own' ? 'chip-content-active' : 'chip-own'}
            chipContent="Own" />
        <Chip
            onClick={() => setSelectedFilter('competitor')}
            containerClass={selectedFilter == 'competitor' ? 'chip-active' : ''}
            contentClass={selectedFilter == 'competitor' ? 'chip-content-active' : 'chip-competitor'}
            chipContent="Competitor" />
    </div >
}