import React from 'react'
import { Source } from '../../types/searchResultTypes';
import cssClasses from "./SingleSearchResult.module.css";

type Props = {
  source: Source,
  logoURL: string,
  getIconFileName: (val: string) => any,
  onClick: () => void
}

function SingleSearchResult({ source, logoURL, getIconFileName, onClick }: Props) {
  return (
    <div {...{ onClick }} className={cssClasses.searchResultsItemInnerContainer}>
      <div className={cssClasses.searchResultsItem + " " + cssClasses.searchHeaderRow}>
        <div className={cssClasses.siteIdContainer}>
          <i style={{ 'color': source?.isCompetitor ? '#5956bc' : '#f67810' }}
            className="fas fa-2x fa-map-marker"></i>&nbsp;&nbsp;{source?.importcode}
        </div>
        <div style={{ flex: 6 }}>
          {source?.displayname}
        </div>
        <div className={cssClasses.iconContainer}>
          {/* <img className={cssClasses.brandIcon} src={"compRef?.logoURL + source?.iconid"} /> */}
          {source?.iconid && <img className={cssClasses.brandIcon} src={logoURL + getIconFileName(source?.iconid)} alt={source.brandname} />}
        </div>
      </div>
    </div>
  )
}

export default SingleSearchResult