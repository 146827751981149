import React, { useEffect, useRef, useState } from "react";
import cssClasses from "./FuelsExpandable.module.css";

type Props = {
  children: JSX.Element;
  headerText: string;
  isExpanded?: boolean
};

/**
 * TODO 1: FuelsExpandable inside FuelsExpandable does not work
 * TODO 2: add props to add icon optionally.
 * @props   `children` children to be rendered when expanded
 * @props   `expandHeight` (optional) height of content when expanded in rem unit. 
 *          send this prop id the default height is too much or too less.
 * @author Anirban Maji
 *
 * this component takes in child element and shows it when expandedn.
 *
 */
function FuelsExpandable({ children, headerText, isExpanded }: Props) {
  const [isExpandedState, setIsExpandableState] = useState(isExpanded??false);
  const contentRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(()=>{
    //this is done to handle the content overflowing after clicking on the header but before full expantion
    if(isExpandedState){
      setTimeout(()=>{
        containerRef.current.setAttribute("style", "overflow: visible")
      }, 200)
      return;
    }
    containerRef.current.setAttribute("style", "overflow: hidden")

  }, [isExpandedState, containerRef ])

  return (
    <div ref={containerRef} className={cssClasses.container}>
      <div
        className={cssClasses.header}
        onClick={() => setIsExpandableState(!isExpandedState)}
      >
        <label style={{}} className={cssClasses.level}>
        <span className={isExpandedState ? cssClasses.arrowOpened :  cssClasses.arrowClosed}>&#9654;</span> {headerText}
        </label>
      </div>
      <div className={cssClasses.content } ref={contentRef}
      style = {
        isExpandedState?
        {
            height: "auto"
        }:
        {
            height: "0px"
        }
      }>
        <div className={cssClasses.customPadding}></div>
        {children}
        <div className={cssClasses.customPadding}></div>
      </div>
      <div className={cssClasses.containerClose}></div>
    </div>
  );
}

export default FuelsExpandable;
