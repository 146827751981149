import DistanceSlider from "../../components/DistanceSlider/DistanceSlider";
import PlacesAutocomplete from "react-google-autocomplete";
import { useEffect, useRef, useState } from "react";
import { Chip } from "@mui/material";

export default (props: {
    setDistance: (d: number) => void, distance: number, setCoordinates: (c: any) => void, country: string[]
}) => {
    const { setDistance, distance, setCoordinates, country } = props;

    const [placesLoaded, setplacesLoaded] = useState(false)
    const initPlaces = () => setplacesLoaded(true)
    const [place, setPlace] = useState<google.maps.places.PlaceResult>()



    useEffect(() => {
        initPlaces()
    }, [])

    useEffect(() => {
        if (place) getHitsByLandmark(place)
    }, [distance])


    const stateRef = useRef<number>();
    stateRef.current = distance;

    const getHitsByLandmark = async (place) => {
        setPlace(place)
        const { geometry: { location: { lat, lng } } } = place;
        setCoordinates({
            lat: lat(), lng: lng()
        });
    }

    const ref = useRef<HTMLInputElement>()

    return <div style={{ display: 'grid', alignItems: 'center', gap: '2rem', gridTemplateColumns: 'max-content 1fr' }} >
        <div>
            {placesLoaded && <div>
                <PlacesAutocomplete {...{ ref }} options={{
                    types: ["neighborhood", "colloquial_area", "locality", "sublocality", "administrative_area_level_3"]
                }} onPlaceSelected={(selectedPlace) => getHitsByLandmark(selectedPlace)} />
            </div>}
            <div>
                {
                    place && <Chip label={place.formatted_address} onDelete={() => {
                        setPlace(undefined)
                        setCoordinates(undefined)
                        ref.current.value = ""
                    }} />
                }
            </div>
        </div>
        <DistanceSlider shouldUseMetric={country.length == 1 && country.includes("CA")} step={.5} onChange={(e, val: number) => setDistance(val)} value={distance} />
    </div>
}