import React, { HTMLAttributes, HTMLInputTypeAttribute, useEffect, useState } from "react";
import cssClasses from "./FuelsUserInputText.module.css";
import cross from "../../resources/cross.svg";
/**
 * TODO 1: change text field clearing icon to actual icon instead of "X"
 * TODO 2: put an enabled/disabled flag
 * TODO 3: keep a required flag
 * TODO 4: keep a password flag, if this is true then render password input
 */

export type FuelsUserInputTextProps = {
  label: string;
  value: string
  changeHandler(userInput: string): any;
  inputRef?: React.MutableRefObject<any>;
  placeholder?: string;
  isPassword?: boolean;
  inputAttributes?: HTMLAttributes<HTMLInputElement>,
  validationFunctions?: ({
    fn: (val: string) => boolean,
    errorMessageToShowIfFalse: string
  })[],
  setErrors?: (errorMessages: string[]) => void,
  errors?: string[]
};

function FuelsUserInputText({
  label,
  changeHandler,
  inputRef,
  isPassword,
  inputAttributes,
  value,
  validationFunctions,
  setErrors,
  errors
}: FuelsUserInputTextProps) {
  useEffect(() => {
    changeHandler(value);
  }, [changeHandler, value]);
  const [isFocusedOrFilled, setIsFocusedOrFilled] = useState(false);

  const handleOnBlur = () => {
    if (value == "") {
      setIsFocusedOrFilled(false)
    } else {
      setIsFocusedOrFilled(true)
    }
  }

  const handleClearField = () => {
    changeHandler("")
    setIsFocusedOrFilled(false)
  }

  const [errorMessage, setErrorMessage] = useState<string>()

  const validate = (val: string) => {
    if (!validationFunctions) return;

    setErrorMessage("")

    let newErrors = errors;

    let localErrors = []

    for (let { errorMessageToShowIfFalse: msg, fn: validate } of validationFunctions) {
      if (validate(val)) {
        newErrors = newErrors.filter(e => e != msg)
      } else {
        newErrors.push(msg)
        localErrors.push(msg)
      }
    }

    if (localErrors.length) setErrorMessage(localErrors.pop())

    setErrors(newErrors)

    if (!errorMessage) {
      changeHandler(val);
    }
  }


  return (
    <div className={cssClasses.container}>

      <div className={cssClasses.itextInputContainer}>
        <input
          {...inputAttributes}
          className={cssClasses.itextInput}
          type={isPassword ? "password" : "text"}
          name=""
          id=""
          value={value}
          onChange={(e) => {
            let val = e.target.value;
            if (validationFunctions) validate(val)
            changeHandler(val);
          }}
          onFocus={() => setIsFocusedOrFilled(true)}
          onBlur={handleOnBlur}
          ref={inputRef}
        />
        {value && (
          <p
            className={cssClasses.itextInputClear}
            onClick={handleClearField}
          >
            <img
              className={cssClasses.closeIcon}
              src={cross}
              alt={"cross"}
              height="10px"
            />
          </p>
        )}
      </div>
      <label htmlFor="" className={isFocusedOrFilled ? cssClasses.labelFocused : cssClasses.labelBlur}>{label}</label>
      {
        errorMessage ? <span style={{
          color: 'red'
        }} >{errorMessage}</span> : ""
      }
    </div>
  );
}

export default FuelsUserInputText;
