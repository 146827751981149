import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "lodash";
import React, { HTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import cssClasses from "./FuelsMultiselectDropdown.module.css";

/** 
 * TODO 1: add props to add icon optionally.
 * TODO 2: add a placeholder text(when nothing is selected) optionally.
 * TODO 3: every dropdown option can have an icon
 * TODO 4: add option to select all
 * TODO 5: make the dropdown keyboard navigable like the dropdown
 * TODO 6: control the scroll when traversing through the list using keyboard
*/

export type FuelsMultiselectDropdownProps = {
  optionList: string[];
  value: string[];
  handleDropdownChange: (selected) => void;
  label?: string;
  placeholder?: string;
  inputProps?: HTMLAttributes<HTMLElement>;
};

function FuelsMultiselectDropdown({
  optionList,
  value,
  handleDropdownChange,
  label,
  placeholder,
  inputProps
}: FuelsMultiselectDropdownProps) {
  const [inputValue, setInputValue] = useState("");
  // const [selected, setSelected] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(0);

  function handleKeyPress(e: any) {
    const optionsLenth = optionList.length;
    switch (e.keyCode) {
      case 38:

        setHoveredIndex((x) => {
          if (x <= 0) {
            return optionsLenth - 1;
          }
          return (x - 1)
        });
        break;
      case 40:
        setHoveredIndex((x) => {
          if (x >= optionsLenth - 1) {
            return 0;
          }
          return (x + 1)
        });
        break;
      case 13:
        addToSelected(optionList[hoveredIndex]);
    }
  }

  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    getOptions(inputValue, setFilteredOptions);
  }, [inputValue]);


  const getOptions = useCallback(
    debounce((text, setOptions) => {
      let filtered = optionList.filter((s) => s.toLowerCase().indexOf(text.toLowerCase()) >= 0)
      setOptions(filtered);
    }, 200),
    []
  );

  function removeFromSelectedList(removeOption: string) {
    handleDropdownChange(() => {
      return [...value.filter((s) => s !== removeOption)];
    });
  }

  function handleChange(e: any) {
    setInputValue(e.target.value);
  }

  function addToSelected(option: string) {
    if (!value.includes(option)) {
      handleDropdownChange([...value, option]);
    }
    setShowDropdown(false);
    setInputValue("");
  }

  let options = filteredOptions.length ? filteredOptions : optionList

  return (
    <div className={cssClasses.container}>
      {label && <label>{label}</label>}
      <div className={cssClasses.itextInputContainer}>
        <input
          {
          ...inputProps
          }
          type="text"
          name="option"
          id="option"
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder || "type to filter"}
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setShowDropdown(false)}
          onKeyDown={handleKeyPress}
          className={cssClasses.itextInput}
          autoComplete="off"
        />
        {inputValue ? (
          <p
            className={cssClasses.itextInputClear}
            onClick={() => setInputValue("")}
          >
            X
          </p>
        ) : <p className={cssClasses.itextInputClear}>
          &#9660;
        </p>}
      </div>

      <div className={cssClasses.selectedOptionContainer}>
        {value.map((s) => {
          return (
            <span className={cssClasses.selectedOption} key={s}>
              {s}
              <span
                className={cssClasses.remove}
                onClick={() => {
                  removeFromSelectedList(s);
                }}
              >
                x
              </span>
            </span>
          );
        })}
      </div>
      {showDropdown && (
        <div
          className={cssClasses.optionsDropdown}
          id="optionDropdown"
          style={{
            top: !label ? "30px" : undefined
          }}
        >
          {options.map((option, ind) => {
            return (
              <div
                key={option}
                onMouseDown={() => addToSelected(option)}
                onMouseOver={() => setHoveredIndex(ind)}
                className={
                  option === options[hoveredIndex]
                    ? cssClasses.optionListHovered
                    : cssClasses.optionList
                }
              >
                {option}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default FuelsMultiselectDropdown;
