import { Coords } from "../advancedSearch/AdvancedSearch";

export type SearchParameter = {
  fieldType: FieldType;
  fieldName: String;
  value: String | number | String[];
};

export enum FieldType {
  wildcard,
  term,
}
export function buildAdvancedSearchQuery(
  searchParams: SearchParameter[],
  pageIndex: number,
  coords?: Coords,
  distance?: number
): string {
  // return '{"query":{"bool":{"must":{"match":{"importcode":"34114"}}}}}'
  const formatedQuery = `{
    "from": ${pageIndex * 100},
    "size": "100",
    "query": {
        "bool": {
            "must": [${searchParams.map((x) => {
              if (
                typeof x.fieldName === "string" &&
                x.fieldName.includes(".")
              ) {
                //nested scenario
                if (Array.isArray(x.value) && x.value.length > 0) {
                  return `{
                    "nested":{
                      "path":"priceSign",
                      "query":{
                        "terms":{
                          "${x.fieldName}":  [${x.value
                    .map((x) => '"' + x + '"')
                    .join(",")}]
                        }
                      }
                    }
                  }`;
                }
              } else if (x.fieldType == FieldType.wildcard) {
                //text field
                return `{
                    "wildcard" : {
                        "${x.fieldName}" : {
                          "value": "${x.value}*",
                          "case_insensitive": true
                        }
                    }
                }`;
              } else if (x.fieldType == FieldType.term) {
                if (Array.isArray(x.value) && x.value.length > 0) {
                  return `{
                    "terms" : {
                        "${x.fieldName}" : [${x.value
                    .map((x) => '"' + x + '"')
                    .join(",")}]
                    }
                }`;
                }
                return `{
                  "term" : {
                      "${x.fieldName}" : "${x.value}"
                  }
              }`;
              }
            })}
            ]

            ${
              (coords && distance) ? `, "filter": {
                "geo_distance": {
                  "distance": "${distance + "mi"}",
                  "location": {
                    "lat": "${coords.lat}",
                    "lon": "${coords.lng}"
                  }
                }
              }` : ''
            }
        }
    }
}`;
  return formatedQuery.replace(/ /g, "");
}
