import { Slider, SliderProps } from '@mui/material'
import { useState } from 'react';
import './DistanceSlider.css'

export default (props:  SliderProps & {shouldUseMetric: boolean}) => {

    const min = 0.5, max = 10.0;

    let convertToLocalUnit = (val: number): number | string => {
        return (props.shouldUseMetric ? val * 1.60934 : val).toFixed(1);
    }

    return <div>
        <label>{`${props.shouldUseMetric ? "Km" : "Miles"} (${convertToLocalUnit(min)}-${convertToLocalUnit(max)})`}</label>
        <Slider {...props} {...{ min, max }} />
        <span>
            {convertToLocalUnit(props.value as number)}
        </span>
    </div>
}