import React, { useCallback, useEffect, useMemo, useState } from "react";
import FuelsExpandable from "../components/fuelsExpandable/FuelsExpandable";
import FuelsUserInputText from "../components/fuelsUserInputText/FuelsUserInputText";
import cssClasses from "./AdvancedSearch.module.css";
import { Button } from "@mui/material";
import SingleSearchResult from "../components/singleSearchResult/SingleSearchResult";
import { ElasticSearchResult, Source } from "../types/searchResultTypes";
import { debounce } from "lodash";
import {
  buildAdvancedSearchQuery,
  FieldType,
  SearchParameter,
} from "../util/elasticsearch";
import FuelsMultiselectDropdown from "../components/fuelsMultiselectDropdown/FuelsMultiselectDropdown";
import { createSearchParam } from "./advancedSearchUtil";
import FuelsChips from "../components/fuelsChips/FuelsChips";
import FuelsLandmarkSearch from "../components/fuelsLandmarkSearch/FuelsLandmarkSearch";

export type Coords = {
  lat: number,
  lng: number
}

type Props = {
  close: React.Dispatch<React.SetStateAction<boolean>>;
  compRef: any;
  getIconFileName: (val: string) => any,
  setShowingFilteredResults: (val: boolean) => void,
};

export const constructHeader = () => {
  return {
    authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    refresh_token: `${window.sessionStorage.getItem("refreshToken")}`,
  };
};

function AdvancedSearch({ close, compRef, getIconFileName, setShowingFilteredResults }: Props) {
  // INPUT FIELDS
  const [siteId, setSiteId] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [seiId, setSeiId] = useState("");
  const [brand, setBrand] = useState<string[]>([]);
  const [txnCompliance, setTxnCompliance] = useState<string[]>([]);
  const [market, setMarket] = useState<string>('');
  const [zone, setZone] = useState<string>('');
  const [pos, setPos] = useState<string[]>([]);
  const [priceSign, setPriceSign] = useState<string[]>([])
  const [country, setCountry] = useState<string[]>([])
  const [selectedFilter, setSelectedFilter] = useState<"all" | "competitor" | "own" | undefined>("all");
  const [coordinates, setCoordinates] = useState<Coords | undefined>(undefined)
  const [distance, setDistance] = useState(.5)

  // DATA FROM CORE SERVICE
  const [searchResults, setSearchResults] = useState<Source[]>([]);
  const [data, setData] = useState<ElasticSearchResult>()

  // DROPDOWN OPTIONS
  const [brandOptions, setBrandOptions] = useState([])
  const [posOptions, setPosOptions] = useState([])
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const [errors, setErrors] = useState<string[]>([])

  const txnComplianceOptionsAndValuesMapping = {
    'Compliant': 'COMPLIANT',
    'Non Compliant': 'NON_COMPLIANT',
    'No Compliance Data': 'NO_COMPLIANCE_DATA'
  };

  const txnComplianceOptions = Object.keys(txnComplianceOptionsAndValuesMapping);

  const advancedSearchDebounced = useCallback(
    debounce((searchParameter: SearchParameter[]) => {
      const advancedQuery = buildAdvancedSearchQuery(searchParameter, currentPageIndex, coordinates, distance);
      compRef.locationService
        .advancedSearch(advancedQuery)
        .subscribe((data: ElasticSearchResult) => {
          setData(data)
          setSearchResults(data.hits.hits.map((hit) => hit._source));
        });
    }, 200),
    [coordinates, distance]
  );

  useEffect(() => {
    if (currentPageIndex > 0) {
      const advancedQuery = buildAdvancedSearchQuery(searchParameter, currentPageIndex, coordinates, distance);
      compRef.locationService
        .advancedSearch(advancedQuery)
        .subscribe((newData: ElasticSearchResult) => {
          setSearchResults([...searchResults, ...newData.hits.hits.map((hit) => hit._source)])
        })
    }
  }, [currentPageIndex])


  const resetFilters = () => {
    setSiteId("")
    setIpAddress("")
    setSeiId("")
    setBrand([])
    setTxnCompliance([])
    setMarket('')
    setZone('')
    setPos([])
    setPriceSign([])
    setCountry([])
    setSearchResults([])
    setSelectedFilter('all');
    setCurrentPageIndex(0);
  }

  const ownedOrcompetitorResults = useMemo(() => {
    if (searchResults.length) {
      if (selectedFilter === 'competitor') {
        return searchResults.filter(result => result.isCompetitor);
      } else if (selectedFilter === 'own') {
        return searchResults.filter(result => !result.isCompetitor);
      }
      else return searchResults;
    }
    return [];
  }, [searchResults, selectedFilter]);


  useEffect(() => {
    compRef.mapSlideService.$brandData.subscribe(setBrandOptions);
    compRef.mapSlideService.$posTypesData.subscribe(setPosOptions);

    compRef.mapSlideService.getBrandDetailsList()
    compRef.mapSlideService.getPosTypesList()
  }, [])

  const [searchParameter, setSearchParameter] = useState([])

  useEffect(() => {
    if (errors.length) return;

    setCurrentPageIndex(0)

    let searchParameter: SearchParameter[] = [];

    let allValuesEmpty = true;

    const pushSearchParamIfValid = (value: string | String[], fieldName: string) => {
      if (value.length) {
        if (allValuesEmpty) allValuesEmpty = false;
        let fieldType =
          (typeof value === 'string' || value instanceof String)
            ? FieldType.wildcard : FieldType.term;
        searchParameter.push(createSearchParam(fieldType, fieldName, value));
      }
    }

    pushSearchParamIfValid(siteId, 'importcode')
    pushSearchParamIfValid(siteId, 'displayname')
    pushSearchParamIfValid(ipAddress, 'ipaddress');
    pushSearchParamIfValid(seiId, 'seiid')

    pushSearchParamIfValid(priceSign, 'priceSign.online')
    pushSearchParamIfValid(country, 'countryCode')
    pushSearchParamIfValid(market, 'market')
    pushSearchParamIfValid(zone, 'zone')
    pushSearchParamIfValid(pos, 'postype')
    pushSearchParamIfValid(brand, 'brandname')

    let txnComplianceFormatted = txnCompliance.map(key => txnComplianceOptionsAndValuesMapping[key])
    pushSearchParamIfValid(txnComplianceFormatted, 'storeCompliance')

    if (allValuesEmpty) setSearchResults([])


    if (searchParameter.length != 0 || coordinates) {
      advancedSearchDebounced(searchParameter)
    }

    setSearchParameter(searchParameter)

  }, [siteId, ipAddress, seiId, pos, country, priceSign, brand, txnCompliance, market, zone, coordinates, distance]);


  return (
    <div className={cssClasses.container}>
      <div className={cssClasses.overlay} onClick={() => close(false)}></div>
      <div className={cssClasses.content}>
        <FuelsExpandable isExpanded={true} headerText={"Search filters"}>
          <div className={cssClasses.filterContainer}>
            <FuelsUserInputText
              validationFunctions={[{
                fn: (v) => !v || /(?! )[-A-Za-z0-9/ #&'.()]+(?<! )$/.test(v),
                errorMessageToShowIfFalse: 'Invalid format'
              }, (() => {
                let characterLimit = 50;
                return {
                  errorMessageToShowIfFalse: `Character length should not exceed ${characterLimit}`,
                  fn: (val) => val.length <= characterLimit
                }
              })()]}

              {...{ setErrors, errors }}

              label={"Site ID"}
              value={siteId}
              changeHandler={setSiteId} />
            <FuelsUserInputText label={"IP Address"} value={ipAddress} changeHandler={setIpAddress} />
            <FuelsUserInputText label={"SEI ID"} value={seiId} changeHandler={setSeiId} />
            <FuelsMultiselectDropdown value={pos} optionList={posOptions.map(p => p.pos_type)} handleDropdownChange={setPos} placeholder={"POS"}></FuelsMultiselectDropdown>
            <FuelsMultiselectDropdown value={brand} optionList={brandOptions.map(b => b.name)} handleDropdownChange={setBrand} placeholder={"Brand"}></FuelsMultiselectDropdown>
            <FuelsMultiselectDropdown value={country} optionList={["US", "CA"]} handleDropdownChange={setCountry} placeholder={"Country"}></FuelsMultiselectDropdown>
            <FuelsUserInputText label={"Market"} value={market} changeHandler={setMarket} />
            <FuelsUserInputText label={"Zone"} value={zone} changeHandler={setZone} />
            {/* <FuelsMultiselectDropdown value={market} optionList={marketOptions.map(m => m.name)} handleDropdownChange={setMarket} placeholder={"Market"}></FuelsMultiselectDropdown>
            <FuelsMultiselectDropdown value={zone} optionList={zoneOptions.map(z => z.name)} handleDropdownChange={setZone} placeholder={"Zone"}></FuelsMultiselectDropdown> */}
            <FuelsMultiselectDropdown value={txnCompliance} optionList={txnComplianceOptions} handleDropdownChange={setTxnCompliance} placeholder={"Compliance"}></FuelsMultiselectDropdown>
            <FuelsMultiselectDropdown value={priceSign} optionList={["true", "false"]} handleDropdownChange={setPriceSign} placeholder={"Price Sign"}></FuelsMultiselectDropdown>
          </div>
        </FuelsExpandable>
        <div style={{ margin: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
          <FuelsChips {...{ selectedFilter, setSelectedFilter }} />
          <FuelsLandmarkSearch {...{ compRef, setDistance, distance, setCoordinates, country }} />
        </div>
        <Button
          startIcon={<i className="fa fa-1x fa-redo"></i>}
          onClick={() => {
            resetFilters();
            compRef.locationService.renderMarkersFromHits(null);
            setShowingFilteredResults(false);
          }}>Reset</Button>
        <div
          onScroll={(e) => {
            const { scrollHeight, clientHeight, scrollTop } = e.target as HTMLElement
            const hasScrolledToBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
            if (hasScrolledToBottom) {
              setCurrentPageIndex(currentPageIndex + 1);
            }
          }}
          style={{
            overflowY: 'scroll'
          }}
          className={cssClasses.resultContriner}>
          {ownedOrcompetitorResults.map((source, i) => {
            // return <p key={i} >{i}</p>
            return <SingleSearchResult onClick={() => {
              compRef.quickSearchService.emitSelected(source);
            }} logoURL={compRef.logoURL} {...{ source, getIconFileName }} key={i} />;
          })}
        </div>
        {searchResults.length > 0 && <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', justifyContent: 'space-around', alignItems: 'center' }} >
          <Button
            onClick={() => {
              setShowingFilteredResults(false);
              compRef.locationService.renderMarkersFromHits(null);

              if (selectedFilter != 'all') {
                let filteredHits = data.hits;

                let shouldKeepIfCompetitor = selectedFilter == 'competitor';

                filteredHits.hits = data.hits.hits.filter(({ _source: { isCompetitor } }) => {
                  return isCompetitor == shouldKeepIfCompetitor
                });

                compRef.locationService.renderMarkersFromHits(filteredHits);
              }
              else compRef.locationService.renderMarkersFromHits(data.hits);
              setShowingFilteredResults(true);
              close(false);
            }} >Show results in Map</Button>
          <span style={{ justifySelf: 'center' }} >{ownedOrcompetitorResults.length + " results"}</span>
        </div>}
      </div>
    </div>
  );
}

export default AdvancedSearch;

