import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AdvancedSearch from './advancedSearch/AdvancedSearch';
import FuelsChips from './components/fuelsChips/FuelsChips';
import "./Search.css";
import { createTheme, IconButton, ThemeProvider } from '@mui/material'

const Search = ({ compRef }) => {

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [criteria, setCriteria] = useState('');
  const [results, setResults] = useState([]);
  const [iconList, setIconList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const searchRef = useRef<any>();
  const [isAdvSearchOpen, setIsAdvSearchOpen] = useState(false);
  const [showingFilteredResults, setShowingFilteredResults] = useState(false)


  const onFocus = () => {
    setIsSearchFocused(true);
    compRef.hideListView.emit();
  };
  const onBlur = () => setIsSearchFocused(false);

  const getIconFileName = useCallback((iconId: string) => {
    if (!iconId || !iconList?.length) return;
    return iconList.find((d) => d.iconid == iconId)?.filename;
  }, [iconList]);

  const searchResults = useMemo(() => {
    if (results) {
      const finalResults = results.map(result => ({ ...result, iconFileName: getIconFileName(result?.iconid) }));
      if (selectedFilter === 'competitor') {
        return finalResults.filter(result => result?.isCompetitor);
      } else if (selectedFilter === 'own') {
        return finalResults.filter(result => !(result?.isCompetitor));
      }
      return finalResults;
    }
    return results;
  }, [results, selectedFilter, iconList, getIconFileName]);

  const filterSearchData = (criteria: string) => {
    compRef.locationService.getSearchLocation(`${criteria}*`).subscribe(data => {
      let results = [];
      if (data && data.hits && data.hits.hits) {
        if (compRef.selectedCountry !== 'both') {
          data.hits.hits = data.hits.hits.filter(hit => {
            let isCanadaMarker = hit._source?.location?.lat >= 48.99 && hit._source?.location?.lat < 60.01 ? true : false;
            if (compRef.selectedCountry === hit._source.countryCode ||
              (hit._source.countryCode == null && ((compRef.selectedCountry === 'US' && !isCanadaMarker)
                || (compRef.selectedCountry === 'CA' && isCanadaMarker)))) {
              return true;
            }
          });
        }
        const sourceMap = data.hits.hits.map((s) => {
          return s._source;
        });
        results = sourceMap.sort((a, b) => {
          return a.importcode - b.importcode;
        });
      } else {
        results = [];
      }
      setResults(results);
    });
  }

  const onSearchTextChange = (evt) => {
    setResults([]);
    setCriteria(evt.target?.value);
    compRef.searchText = evt.target?.value;
    if (evt.target?.value && evt.target?.value?.length && evt.target?.value?.length >= 2) {
      filterSearchData(evt.target?.value);
    }
  }

  const clearAll = () => {
    compRef.searched.emit(true);
    setCriteria('');
    setResults([]);
    setSelectedFilter('all');
  }

  useEffect(() => {
    compRef.clearAll = clearAll;
    compRef.searchRef = searchRef;
    compRef.mapSlideService.$iconsData.subscribe((icon: any) => {
      setIconList(icon);
    });
  }, []);

  const selectResult = (value) => {
    compRef.quickSearchService.emitSelected(value);
    setCriteria('');
    setResults([]);
  }

  const showTooltip = (event, msg) => {
    const position = {
      x: event.pageX,
      y: event.pageY,
    };
    compRef?.showTooltip(position, msg);
  }

  const hideTooltip = () => {
    compRef?.hideTooltip();
  }

  const renderResultItems = () => {
    if (!searchResults?.length) {
      return (
        <div className="no-match-container">
          No sites match your criteria
        </div>
      );
    };

    return (
      <div className="search-results-item-container">
        {searchResults.map((result, index) => {
          return <div onClick={() => selectResult(result)} key={index} className="search-results-item-inner-container">
            <div className="search-results-item search-header-row">
              <div className="site-id-container">
                <i style={{ 'color': result?.isCompetitor ? '#5956bc' : '#f67810' }}
                  className="fas fa-2x fa-map-marker"></i>&nbsp;&nbsp;{result?.importcode}
              </div>
              <div style={{ flex: 6 }}>
                {result?.displayname}
              </div>
              <div onMouseEnter={(e) => showTooltip(e, result?.brandname)} onMouseLeave={hideTooltip} className="icon-container">
                {result?.iconFileName && <img className="brand-icon" src={compRef?.logoURL + result?.iconFileName} />}
              </div>
            </div>
          </div>
        }
        )}
      </div>
    );
  }

  const renderResultsContainer = () => {
    if (!results?.length) return;
    return (
      <div className="search-results-container">
        <FuelsChips {...{ selectedFilter, setSelectedFilter }} />
        {searchResults?.length ? <div className="search-header-row">
          <div className="search-header-title" style={{ flex: 3 }}>
            Site ID
          </div>
          <div className="search-header-title" style={{ flex: 6 }}>
            Description
          </div>
          <div className="search-header-title" style={{ flex: 1.2 }}>
            Fuel Brand
          </div>
        </div> : ''}
        {renderResultItems()}
      </div >
    );
  }

  const renderInput = () => {
    return (
      <input
        type="text"
        ref={searchRef}
        className={`form-control ${isSearchFocused && 'input-focused'}`}
        value={criteria}
        placeholder="Search Sites"
        onChange={onSearchTextChange}
        autoFocus onFocus={onFocus}
        onBlur={onBlur} />
    );
  }

  const openAdvanceSearch = () => {
    setIsAdvSearchOpen(() => true);
  }

  const renderInputContainer = () => {
    return (
      <div className="input-group">
        <div className={`input-group-prepend ${isSearchFocused && 'input-focused-icon-container'}`} onClick={openAdvanceSearch}>
          <span
            className={`input-group-text fa fa-lg fa-search ${isSearchFocused && 'input-focused-icon'}`}>
          </span>
        </div>
        {renderInput()}
        <div className="input-group-append">
          {showingFilteredResults && <IconButton onClick={() => {
            compRef.locationService.renderMarkersFromHits(null);
            setShowingFilteredResults(false)
          }}>
            <i className='fa fa-1x fa-filter'></i>
          </IconButton>}
          {criteria?.length ? <span onClick={clearAll} onMouseEnter={(e) => showTooltip(e, 'Clear Search')} onMouseLeave={hideTooltip} className="fas fa-lg fa-times"></span> : ''}
        </div>
        {isAdvSearchOpen && <AdvancedSearch close={setIsAdvSearchOpen}{...{ compRef, getIconFileName, setShowingFilteredResults }} />}
      </div>
    );
  }

  const theme = createTheme({
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    },
    palette: {
      primary: {
        main: "#008000"
      }
    }
  })

  return (
    <ThemeProvider {...{ theme }} >
      <div className="search-container">
        {renderInputContainer()}
        {renderResultsContainer()}
      </div>
    </ThemeProvider>
  );
}

export default Search;
